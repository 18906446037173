@font-face {
    font-family: Marianne;
    src: url(/assets/fonts/Marianne/Marianne-Regular.woff2) format("woff2"),url(/assets/fonts/Marianne/Marianne-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Marianne;
    src: url(/assets/fonts/Marianne/Marianne-Regular_Italic.woff2) format("woff2"),url(/assets/fonts/Marianne/Marianne-Regular_Italic.woff) format("woff");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: Marianne;
    src: url(/assets/fonts/Marianne/Marianne-Medium.woff2) format("woff2"),url(/assets/fonts/Marianne/Marianne-Medium.woff) format("woff");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: Marianne;
    src: url(/assets/fonts/Marianne/Marianne-Medium_Italic.woff2) format("woff2"),url(/assets/fonts/Marianne/Marianne-Medium_Italic.woff) format("woff");
    font-weight: 500;
    font-style: italic
}

@font-face {
    font-family: Marianne;
    src: url(/assets/fonts/Marianne/Marianne-Bold.woff2) format("woff2"),url(/assets/fonts/Marianne/Marianne-Bold.woff) format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: Marianne;
    src: url(/assets/fonts/Marianne/Marianne-Bold_Italic.woff2) format("woff2"),url(/assets/fonts/Marianne/Marianne-Bold_Italic.woff) format("woff");
    font-weight: 700;
    font-style: italic
}

